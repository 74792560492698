import { RoleMenu } from "./menu.model";

export const RoleMenusItems = [
  {
    menuId: 1,
    roles: ["SuperAdmin"],
  },
  {
    menuId: 2,
    roles: ["StandardUser"],
  },
  {
    menuId: 11,
    roles: ["StandardUser"],
  },
  {
    menuId: 12,
    roles: ["TenantAdmin"],
  },
  {
    menuId: 3,
    roles: ["TenantAdmin"],
  },
  // {
  //   menuId: 14,
  //   roles: ["TenantAdmin", "StandardUser"],
  // },
  {
    menuId: 4,
    roles: ["TenantAdmin"],
  },
  {
    menuId: 5,
    roles: ["TenantAdmin"],
  },
  {
    menuId: 18,
    roles: ["TenantAdmin"],
  },
  {
    menuId: 20,
    roles: ["TenantAdmin"],
  },
  // {
  //   menuId: 7,
  //   roles: ["TenantAdmin", "StandardUser"],
  // },
  // {
  //   menuId: 23,
  //   roles: ["TenantAdmin", "SuperAdmin", "StandardUser"],
  // },
  // {
  //   menuId: 26,
  //   roles: ["TenantAdmin", "SuperAdmin", "StandardUser"],
  // },
  // {
  //   menuId: 29,
  //   roles: ["TenantAdmin", "SuperAdmin", "StandardUser"],
  // },
  {
    menuId: 22,
    roles: ["TenantAdmin"],
  },
  {
    menuId: 87,
    roles: ["TenantAdmin"],
  },
  // {
  //   menuId: 15,
  //   roles: ["StandardUser", "TenantAdmin"],
  // },
  // {
  //   menuId: 16,
  //   roles: ["StandardUser", "TenantAdmin"],
  // },
  // {
  //   menuId: 17,
  //   roles: ["StandardUser", "TenantAdmin"],
  // },

  {
    menuId: 21,
    roles: ["TenantAdmin"],
  },

  {
    menuId: 9,
    roles: ["SuperAdmin"],
  },
  {
    menuId: 10,
    roles: ["TenantAdmin"],
  },

  // {
  //   menuId: 13,
  //   roles: ["StandardUser", "TenantAdmin"],
  // },
  // {
  //   menuId: 90,
  //   roles: ["StandardUser"],
  // },
];
