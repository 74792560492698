import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { userService } from 'src/app/services/userManagement.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  form!: FormGroup;
  constructor(  private formBuilder: FormBuilder, private service : userService,   private snackBar: MatSnackBar,   private router: Router,) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
     
      username: ["", Validators.required],
      email: ["", Validators.required],
     
    });
  }
  changePassword(){
    this.service.fogetPassoword(this.form.value).subscribe({
      next: (res) => {
        this.snackBar.open("Email sent Successfully!", "", {
          duration: 2500,
          panelClass: ["blue-snackbar"],
          verticalPosition: "bottom",
          horizontalPosition: "left",
        });
        this.router.navigate(["confirmation"]);
      },
    });
  }
  }


