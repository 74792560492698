import { Menu } from "./menu.model";

export const menuItems = [
  new Menu(1, "ADMIN_NAV.DASHBOARD", "/", null, "dashboard", null, false, 0),
  // new Menu(2, "ADMIN_NAV.LEVEL1", null, null, "card_membership", null, true, 0),
  // new Menu(
  //   10,
  //   'ADMIN_NAV.LEVEL2',
  //   '/membership/new',
  //   null,
  //   'person_add',
  //   null,
  //   false,
  //   2
  // ),
  new Menu(
    11,
    "ADMIN_NAV.LEVEL2_1",
    "/membership/list",
    null,
    "people",
    null,
    false,
    0
  ),
  new Menu(
    90,
    "ADMIN_NAV.LEVEL14",
    "/membership/payment",
    null,
    "people",
    null,
    false,
    2
  ),
  new Menu(
    12,
    "ADMIN_NAV.LEVEL2_2",
    "/membership/update",
    null,
    "people",
    null,
    false,
    2
  ),
  new Menu(
    14,
    "ADMIN_NAV.LEVEL4",
    "/plan/list",
    null,
    "subject",
    null,
    false,
    3
  ),
  // new Menu(
  //   15,
  //   'ADMIN_NAV.LEVEL4_1',
  //   '/plan/new',
  //   null,
  //   'playlist_add',
  //   null,
  //   false,
  //   14
  // ),
  // new Menu(
  //   15,
  //   'ADMIN_NAV.LEVEL4_2',
  //   '/plan/list',
  //   null,
  //   'list',
  //   null,
  //   false,
  //   14
  // ),

  new Menu(
    4,
    "ADMIN_NAV.LEVEL5",
    "/customer/list",
    null,
    "people_outline",
    null,
    false,
    3
  ),
  // new Menu(
  //   16,
  //   'ADMIN_NAV.LEVEL5_1',
  //   '/customer/new',
  //   null,
  //   'add_circle_outline',
  //   null,
  //   false,
  //   4
  // ),
  // new Menu(
  //   16,
  //   'ADMIN_NAV.LEVEL5_2',
  //   '/customer/list',
  //   null,
  //   'list',
  //   null,
  //   false,
  //   4
  // ),

  new Menu(5, "ADMIN_NAV.LEVEL6", null, null, "fitness_center", null, true, 0),
  // new Menu(
  //   17,
  //   'ADMIN_NAV.LEVEL6_1',
  //   '/facility/new',
  //   null,
  //   'add_circle_outline',
  //   null,
  //   false,
  //   5
  // ),
  new Menu(
    18,
    "ADMIN_NAV.LEVEL6_2",
    "/facility/list",
    null,
    "list",
    null,
    false,
    5
  ),
  // new Menu(6, "ADMIN_NAV.LEVEL7", null, null, "people_outline", null, true, 5),
  // // new Menu(
  //   19,
  //   "ADMIN_NAV.LEVEL7_1",
  //   "/facility/member/new",
  //   null,
  //   "add_circle_outline",
  //   null,
  //   false,
  //   6
  // ),
  new Menu(
    20,
    "ADMIN_NAV.LEVEL7_2",
    "/facility/member/list",
    null,
    "people_outline",
    null,
    false,
    5
  ),
  // new Menu(
  //   21,
  //   'ADMIN_NAV.LEVEL7_3',
  //   '/facility/Create/Account',
  //   null,
  //   'list',
  //   null,
  //   false,
  //   6
  // ),
  // new Menu(
  //   21,
  //   'ADMIN_NAV.LEVEL7_4',
  //   '/facility/account/list',
  //   null,
  //   'list',
  //   null,
  //   false,
  //   6
  // ),

  new Menu(7, "ADMIN_NAV.LEVEL8", null, null, "monetization_on", null, true, 0),
  // new Menu(21, "ADMIN_NAV.LEVEL8_1", null, null, "category", null, true, 7),
  // // new Menu(
  //   22,
  //   "ADMIN_NAV.LEVEL8_1_1",
  //   "/sales/category/new",
  //   null,
  //   "add_circle_outline",
  //   null,
  //   false,
  //   21
  // ),
  new Menu(
    23,
    "ADMIN_NAV.LEVEL8_1_2",
    "/sales/category/list",
    null,
    "category",
    null,
    false,
    7
  ),
  // new Menu(24, "ADMIN_NAV.LEVEL8_2", null, null, "grid_on", null, true, 7),
  // new Menu(
  //   25,
  //   "ADMIN_NAV.LEVEL8_2_1",
  //   "/sales/product/new",
  //   null,
  //   "add_circle_outline",
  //   null,
  //   false,
  //   24
  // ),
  new Menu(
    26,
    "ADMIN_NAV.LEVEL8_2_2",
    "/sales/product/list",
    null,
    "grid_on",
    null,
    false,
    7
  ),
  // new Menu(
  //   27,
  //   "ADMIN_NAV.LEVEL8_3",
  //   null,
  //   null,
  //   "monetization_on",
  //   null,
  //   true,
  //   7
  // ),
  // new Menu(
  //   28,
  //   "ADMIN_NAV.LEVEL8_3_1",
  //   "/sales/new",
  //   null,
  //   "add_circle_outline",
  //   null,
  //   false,
  //   27
  // ),
  new Menu(
    29,
    "ADMIN_NAV.LEVEL8_3_2",
    "/sales/list",
    null,
    "monetization_on",
    null,
    false,
    7
  ),

  new Menu(10, "ADMIN_NAV.LEVEL11", null, null, "person", null, true, 0),
  // new Menu(
  //   11,
  //   "ADMIN_NAV.LEVEL11_1",
  //   "user/new",
  //   null,
  //   "add_circle_outline",
  //   null,
  //   false,
  //   10
  // ),
  new Menu(
    12,
    "ADMIN_NAV.LEVEL11_2",
    "user/list",
    null,
    "list",
    null,
    false,
    10
  ),

  // new Menu(
  //   22,
  //   "ADMIN_NAV.LEVEL7_5",
  //   "/reset/:id",
  //   null,
  //   "lock",
  //   null,
  //   false,
  //   10
  // ),
  new Menu(
    87,
    "ADMIN_NAV.LEVEL12",
    "/Role/list",
    null,
    "add_circle_outline",
    null,
    false,
    10
  ),
  new Menu(
    13,
    "ADMIN_NAV.LEVEL10",
    null,
    null,
    "account_balance_wallet",
    null,
    true,
    0
  ),
  new Menu(
    14,
    "ADMIN_NAV.LEVEL10_1",
    "/accounting/account",
    null,
    "account_balance_wallet",
    null,
    false,
    13
  ),
  new Menu(
    15,
    "ADMIN_NAV.LEVEL10_3",
    "/accounting/subtype",
    null,
    "account_balance_wallet",
    null,
    false,
    13
  ),
  new Menu(
    16,
    "ADMIN_NAV.LEVEL10_2",
    "/accounting/type",
    null,
    "account_balance_wallet",
    null,
    false,
    13
  ),
  new Menu(
    17,
    "ADMIN_NAV.LEVEL10_4",
    "/accounting/class",
    null,
    "account_balance_wallet",
    null,
    false,
    13
  ),
  new Menu(3, "ADMIN_NAV.LEVEL3", null, null, "settings", null, true, 0),
  // new Menu(8, 'ADMIN_NAV.LEVEL9', null, null, 'home', null, true, 3),
  // // new Menu(
  //   9,
  //   'ADMIN_NAV.LEVEL9_1',
  //   '/tenant/new',
  //   null,
  //   'add_circle_outline',
  //   null,
  //   false,
  //   8
  // ),
  new Menu(
    9,
    "ADMIN_NAV.LEVEL9_1_2",
    "/tenant/list",
    null,
    "home",
    null,
    false,
    0
  ),
  new Menu(
    21,
    "ADMIN_NAV.LEVEL13",
    "/tenant/branch/list",
    null,
    "Branch",
    null,
    false,
    3
  ),
];
