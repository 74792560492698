<div class="card-container">
  <mat-card class="custom-card">
    <mat-card-header>
      <mat-card-title>Create Password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" autocomplete="off" fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="60" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>User Name</mat-label>
            <input matInput formControlName="username" />
            <mat-error>*userName is required</mat-error>
          </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="60" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>New Password</mat-label>
            <input
              matInput
              formControlName="newPassword"
              [type]="showPassword ? 'text' : 'password'"
            />
            <mat-icon matSuffix (click)="togglePasswordVisibility()">
              {{ showPassword ? "visibility" : "visibility_off" }}</mat-icon
            >

            <mat-error>*new Password is required</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="60" class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Confirm Password</mat-label>
            <input
              matInput
              formControlName="confirmPassword"
              [type]="showPassword ? 'text' : 'password'"
            />

            <mat-icon matSuffix (click)="togglePasswordVisibility()">
              {{ showPassword ? "visibility" : "visibility_off" }}</mat-icon
            >
            <mat-error *ngIf="form?.controls.newPassword.errors?.required"
              >*Confirm Password is required</mat-error
            >
            <mat-error
              *ngIf="
                form?.controls.confirmPassword.hasError('mismatchedPasswords')
              "
              >Passwords do not match</mat-error
            >

            <!-- <mat-error *ngIf="F.confirmPassword?.errors?.['MustMatch']"
              >*hhh is required</mat-error
            > -->
          </mat-form-field>
        </div>

        <div fxFlex="100" class="mt-2 w-100">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="changePassword()"
          >
            Create Password
          </button>
          <!-- <button
            mat-raised-button
            color="warn"
            style="margin-left: 10px"
            routerLink="/tenant/list"
          >
            CANCEL
          </button> -->
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
