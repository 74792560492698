import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpApi } from '../core/http/http-api';
import { Account } from './login/login/login.model';
import { LoginForm } from '../models/LoginForms';


const OAUTH_DATA = environment.host;
export type EntityResponseType = HttpResponse<Account>;
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }
  token !: any;
  incorrectCredential: boolean = false;
  register(userRequest: any): Observable<any> {
    const data = {
      code: userRequest.codigo,
      email: userRequest.email,
      password: userRequest.password
    };

    return this.http.post(HttpApi.userRegister, data)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  loginWithUserCredentials(loginInfo: LoginForm): Observable<EntityResponseType> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', ' application/json');
    // headers = headers.set('Accept', ' application/json');
    const body = new URLSearchParams();
    return this.http.post<EntityResponseType>(HttpApi.oauthLogin, loginInfo, { headers })
      .pipe(
        map((response: any) => {
          localStorage.setItem('access_token', JSON.stringify(response.accessToken));
          localStorage.setItem('roles', JSON.stringify(response.roles));
          localStorage.setItem('userType', JSON.stringify(response.userType));
         
          localStorage.setItem('username',JSON.stringify(response.username));
          console.log("this is response" + response._id)
          localStorage.setItem('_id',JSON.stringify(response._id));
          // console.log("this is response" + response.access_token)

          return response;
        })
      );
  }

  loginWithRefreshToken(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', ' application/json');
    // headers = headers.set('Accept', ' application/json');

    // const refresh = {
    //   'refresh_token': this.refreshToken
    // }

    // return this.http.post<EntityResponseType>(HttpApi.oauthRefreshToken, JSON.stringify(refresh), { headers })
    //   .pipe(
    //     map((response: any) => {
    //       localStorage.setItem('token_response', JSON.stringify(response));
    //       return response;
    //     })
    //   );
    return this.http.post(HttpApi.oauthLogin, { headers })
      .pipe(
        map((response: any) => {
          localStorage.setItem('access_token', JSON.stringify(response.token));
          return response;
        })
      );

  }
  haveAccess(roles: string[]): boolean {
    const role = JSON.parse(localStorage.getItem('userType'));

    return roles.includes(role) ? true : false;
  }


  isLogged(): boolean {
    return localStorage.getItem('access_token') ? true : false;
  }

  logout(): void {
    localStorage.clear();
    window.location.reload();

  }
  logoutpage(): any {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.set("Access-Control-Allow-Headers", "content-type");
    headers = headers.set('Authorization', `Bearer ${JSON.parse(localStorage['token_response']).access_token}`);
    const body = new URLSearchParams();
    body.set('client_id', 'admin-cli');
    body.set('client_secret', '2c732ea4-53c3-44a0-97aa-b10b431f4176');
    body.set('refresh_token', JSON.parse(localStorage['token_response']).refresh_token)
    return this.http.post(`${'http://20.98.161.74:8080/api/logout'}?${body.toString()}`, { headers });

  }

  get accessToken() {

    // console.log("I am refresh"+JSON.parse(localStorage['token_response']).access_token)
    return localStorage['access_token'] ? JSON.parse(localStorage['access_token']) : null;
  }

  get refreshToken() {

    return localStorage['access_token'] ? JSON.parse(localStorage['access_token']) : null;
  }
  get roles() {
    return localStorage['roles'] ? JSON.parse(localStorage['roles']) : null;
  }
  get userType() {
    return localStorage['userType'] ? JSON.parse(localStorage['userType']) : null;
  }
}
