import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpApi } from "../core/http/http-api";
import { User } from "../models/user";
import { changePassword } from "../models/changePassword";

@Injectable({
  providedIn: "root",
})
export class userService {
  constructor(private http: HttpClient) {}

  post(data: any) {
    return this.http.post<any>(HttpApi.users, data);
  }
  getAll() {
    return this.http.get<User[]>(HttpApi.user);
  }
  put(data: User, id: number) {
    return this.http.put<User>(HttpApi.Edituser + id, data);
  }
  delete(id: number) {
    return this.http.delete<User>(HttpApi.users + id);
  }
  getById(id: string) {
    return this.http.get<User>(HttpApi.users + id);
  }
  changePassword(data: changePassword, id: string) {
    return this.http.put<changePassword>(HttpApi.changepassword + id, data);
  }
  fogetPassoword(data: any) {
    return this.http.post<any>(HttpApi.forgetPassword, data);
  }
  resetPassword(data: changePassword , id: string) {
    return this.http.put<changePassword>(HttpApi.resetPassword + id , data);
  }
  getprofileByID(id: string) {
    return this.http.get<any>(HttpApi.user + id);
  }
  editProfile(data: any, id: string){
    return this.http.put<any>(HttpApi.user + id, data);
  }
}
