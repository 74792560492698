import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ResetPasswordService } from "src/app/services/resetPassword.service";
import { matchingPasswords } from "src/app/theme/utils/app-validators";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  form!: FormGroup;

  code: any;
  public showPassword: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private resetService: ResetPasswordService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.code = params["id"];
      }
    });
    this.form = this.formBuilder.group(
      {
        code: [this.code, Validators.required],
        username: ["", Validators.required],
        newPassword: ["", Validators.required],
        confirmPassword: ["", Validators.required],
      },
      { validator: matchingPasswords("newPassword", "confirmPassword") }
    );
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  changePassword() {
    this.resetService.resetPassword(this.form.value).subscribe({
      next: (res) => {
        // console.log(res);
        this.snackBar.open("Password Created Successfully!", "×", {
          panelClass: ["blue-snackbar"],
          verticalPosition: "bottom",
          horizontalPosition: "left",
        });
        this.router.navigate(["auth/login"]);
      },
    });
  }

  getErrorMessage(controlName: string) {
    if (this.form.controls[controlName].hasError("minlength")) {
      return "Must be at least 2 characters";
    }
    return "Passwords must match";
  }
}

function passwordMatchValidator(g: FormGroup) {
  const password = g.get("newPassword").value;
  const confirm = g.get("confirmPassword").value;
  return password === confirm ? null : { mismatch: true };
}
