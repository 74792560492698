import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from "@agm/core";

import { OverlayContainer, Overlay } from "@angular/cdk/overlay";
import { MAT_MENU_SCROLL_STRATEGY } from "@angular/material/menu";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { menuScrollStrategy } from "./theme/utils/scroll-strategy";

import { environment } from "src/environments/environment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    environment.url + "/assets/i18n/",
    ".json"
  );
}

import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";

import { AppSettings } from "./app.settings";
import { AppService } from "./app.service";

import { AuthGuard } from "./auth/guards/auth-guard.service";
import { Oauth2Interceptor } from "./core/http/oauth2.interceptor";
import { AppInterceptor } from "./core/http/http.interceptor";
import { CoreModule } from "./core/core.module";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I",
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    //  CoreModule,
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  providers: [
    AppSettings,
    AppService,
    AuthGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Oauth2Interceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
