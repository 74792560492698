<h1 mat-dialog-title>Update Profile</h1>
<form [formGroup]="form" autocomplete="off" class="user-form" (submit)="UpdateUser()">
    <div mat-dialog-content>
        <div fxLayout="row wrap" class="mt-2">

            <div fxFlex="100" fxFlex.gt-md="100" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>username</mat-label>
                    <input matInput formControlName="username" readonly />
                    <mat-error>username is required</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>fullName</mat-label>
                    <input matInput formControlName="fullName" required />
                    <mat-error>fullName is required</mat-error>
                </mat-form-field>
            </div>
            <!-- <div fxFlex="100" fxFlex.gt-md="50" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Lastname</mat-label>
                    <input matInput formControlName="lastname" required />
                    <mat-error>Lastname is required</mat-error>
                </mat-form-field>
            </div> -->
            <div fxFlex="100" fxFlex.gt-md="100" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Mobile</mat-label>
                    <input matInput formControlName="phoneNo" required />
                    <mat-error>mobile is required</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Address</mat-label>
                    <input matInput formControlName="address" required />
                    <mat-error>address is required</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-md="100" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required />
                    <mat-error>email is required</mat-error>
                </mat-form-field>
            </div>


        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close>
                Close
            </button>
            <button mat-raised-button color="primary" type="submit">Update</button>
        </div>
    </div>
</form>