<html>

<head>
  <title>Forgot Password Confirmation</title>
  <style>
    body {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin: 0;
      background: #f5f5f5;
      font-family: Arial, sans-serif;
    }

    .message-container {
      text-align: center;
      background: #ffffff;
      padding: 20px;
      border-radius: 5px;
    }
  </style>
</head>

<body>
  <div class="message-container">
    <h2>Forgot Password Confirmation</h2>
    <p>Please check your email to reset your password.</p>
  </div>
</body>

</html>