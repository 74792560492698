import { RoleMenusItems } from './roleMenu';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AppSettings, Settings } from '../../../app.settings';
import { MenuService } from './menu.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class MenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Array<any>;
  public settings: Settings;
  constructor(public appSettings: AppSettings, public menuService: MenuService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    let roleMenusItems = RoleMenusItems;
    let allowdMenuItems = roleMenusItems.filter(x => x.roles.includes(JSON.parse(localStorage["userType"])))
    let blockedMenuItems = roleMenusItems.filter(x => !x.roles.includes(JSON.parse(localStorage["userType"])))
    let menuIds = [];
    let blockedMenuIds = [];
    // console.log(allowdMenuItems);

    allowdMenuItems.forEach(e => {
      menuIds.push(e.menuId);
    });
    blockedMenuItems.forEach(e => {
      blockedMenuIds.push(e.menuId);
    });

    // this.menuItems = this.menuItems.filter(item => menuIds.includes(item.id) || (menuIds.includes(item.parentId) && !blockedMenuIds.includes(item.id)));
    this.menuItems = this.menuItems.filter(item => menuIds.includes(item.id));
    // console.log(this.menuItems);
    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
  }

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }

}
