import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { NotFoundComponent } from "./shared/not-found/not-found.component";
import { AuthGuard } from "./auth/guards/auth-guard.service";
import { LoginComponent } from "./auth/login/login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { ResetPasswordComponent } from "./admin/facility/reset-password/reset-password.component";
import { ResettPasswordComponent } from "./admin/user-management/resett-password/resett-password.component";
import { ForgetPasswordComponent } from "./admin/user-management/forget-password/forget-password.component";
import { ConfirmationComponent } from "./admin/user-management/confirmation/confirmation.component";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    canActivate: [AuthGuard],
  },

  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  //{ path: 'auth/login', component:LoginComponent},
  {
    path: "createpassword/:id",
    component: ResetPasswordComponent,
    data: { breadcrumb: "reset password " },
  },
  {
    path: "forgetPassword",
    component: ForgetPasswordComponent,
    data: { breadcrumb: "reset password " },
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    data: { breadcrumb: 'User List' },
  },

  {
    path: "redirect",
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "resetPassword/:id",
    component: ResettPasswordComponent,
    data: { breadcrumb: "reset password " },
  },
  { path: "**", component: NotFoundComponent },
  // {
  //   path: "facility/reset/:id",
  //   loadChildren: () =>
  //     import("./admin/facility/facility.module").then((m) => m.FacilityModule),
  // },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: "legacy",
      initialNavigation: "enabledBlocking", // for one load page, without reload
       useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
