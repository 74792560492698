import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpApi } from '../core/http/http-api';
import { FacilityMember } from '../models/facilityMember';
import { resetPassword } from '../models/resetPassword';
import { Tenant } from '../models/tenant';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordService {
  constructor(private http: HttpClient) {}

  resetPassword(data: any) {
    return this.http.post<any>(HttpApi.reset, data);
  }

  //   changePassword(data) {
  //     var headers = new HttpHeaders().set(
  //       'Authorization',
  //       'Token ' + localStorage.getItem('usertoken')
  //     );

  //     var options = {
  //       headers: headers,
  //     };
  //     return this.http.post(HttpApi.reset, data, options);
  //   }
}
