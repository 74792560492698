import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { userService } from "src/app/services/userManagement.service";

@Component({
  selector: "app-resett-password",
  templateUrl: "./resett-password.component.html",
  styleUrls: ["./resett-password.component.scss"],
})
export class ResettPasswordComponent implements OnInit {
  form!: FormGroup;
  UserId: any;

  public showPassword: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private service: userService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
     console.log(id);
    this.form = this.formBuilder.group({
      // code: [this.code, Validators.required],
      newPassword: ["", Validators.required],
      UserId: [this.UserId, Validators.required],
      // password: ["", Validators.required],
    });
  }
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  resetPassword() {
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    this.service.resetPassword(this.form.value, id).subscribe({
      next: (res) => {
        this.snackBar.open("Your password has been reset!", "", {
          duration: 2500,
          panelClass: ["blue-snackbar"],
          verticalPosition: "bottom",
          horizontalPosition: "left",
        });
        this.router.navigate(["auth/login"]);
      },
    });
  }
}
