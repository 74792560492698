<div class="card-container">
    <mat-card class="custom-card">
      <mat-card-header>
        <mat-card-title>Reset your password</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" autocomplete="off" fxLayout="row wrap">
            <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>UserName</mat-label>
                  <input matInput formControlName="username" />
                  <mat-error>*User Name is required</mat-error>
                </mat-form-field>
              </div>
          <div fxFlex="100" fxFlex.gt-sm="100" class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
              <mat-error>*Email is required</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100" class="mt-2 w-100">
            <button style="background-color: #1976d2; color: white;" mat-raised-button type="submit" [ngStyle]="{'width': '100%'}"
              (click)="changePassword()">
              Send Email
            </button>
  
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>