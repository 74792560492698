import { environment } from "src/environments/environment";

export class HttpApi {
  // static BaseUrl = environment.host
  static membershipBaseUrl ="members/";
  static paymnet= "transaction/"
  // OAuthoauth
  static oauthLogin = "auth/login";
  static userRegister = "auth/login";
  static userDestroy = "auth/login";
  static userUpdate = "auth/login";
  static userRead = "auth/login";
  static oauthRefreshToken = "auth/login";
  static plan = "plans/";
  static customer = "customers/";
  static facility = "facilities/";
  static facilityMember = "facilitymembers/";
  static category = "categories/";
  static sale = "sales/";
  static product = "products/";
  static tenant = "tenants/";
  static tenantResendEmail = "tenants/resend";
  static reset = "auth/createPassword";
  static user = "users/";
  static users = "auth/register/";
  static Edituser = "auth/edit/";
  static account = "accounts/";
  static accountClass = "accountclasses/";
  static accountType = "accountTypes/";
  static accountSubType = "accountSubTypes/";
  static role = "roles/";
  static Assignrole = "userroles/";
  static Assignaccount = "accountAssignments/";
  static Branch = "branchs/";
  static changepassword = "auth/changepassword/"
  static forgetPassword ="forgotPassword/sendEmail/ "
  static resetPassword= "forgotPassword/resetPassword/"
 
  // static Assignedrole = 'userroles/';
}
