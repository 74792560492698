import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { userService } from 'src/app/services/userManagement.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  public form: UntypedFormGroup;
  public user: any;
  constructor(  private formBuilder: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: userService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditProfileComponent>) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ["", Validators.required],
      fullName: ["", Validators.required],
      userType: ["", Validators.required],
      phoneNo: ["", Validators.required],
      email: ["", Validators.required],
      address:["", Validators.required]
     
    });
    if (this.data.user) {
      console.log(this.data.user);
      this.form.patchValue(this.data.user);
    }
  }
  
  UpdateUser() {
    const profileusername = localStorage.getItem("_id");
    const id = profileusername.replace(/['"]+/g, "");
    console.log(id);
    if (this.form.valid) {
      const user = this.form.value.username;
      this.userService
        .editProfile(this.form.value, id)
        .subscribe({
          next: (res) => {
            this.snackBar.open("Profile Updated Successfully", "", {
              duration: 2500,
              verticalPosition: "bottom",
              horizontalPosition: "left",
            });
            // this.router.navigate(['facility/member/list']);
            this.form.reset();

            this.dialogRef.close("save");
          },
          error: () => {
            alert("error");
          },
        });
    }
  }

}
