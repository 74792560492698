<div class="card-container">
    <mat-card class="custom-card">
        <mat-card-header>
            <mat-card-title>Reset Password</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="form" autocomplete="off" fxLayout="row wrap">


                <div fxFlex="100" fxFlex.gt-sm="90" class="px-1">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>newPassword</mat-label>
                        <input matInput formControlName="newPassword" [type]="showPassword ? 'text' : 'password'" />
                        <mat-icon matSuffix (click)="togglePasswordVisibility()">
                            {{ showPassword ? "visibility" : "visibility_off" }}</mat-icon>

                        <mat-error>*Password is required</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="100" fxFlex.gt-sm="90" class="px-1">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>confirmPassword</mat-label>
                        <input matInput formControlName="confirmPassword" [type]="showPassword ? 'text' : 'password'" />
                        <mat-icon matSuffix (click)="togglePasswordVisibility()">
                            {{ showPassword ? "visibility" : "visibility_off" }}</mat-icon>

                        <mat-error>*Password is required</mat-error>
                    </mat-form-field>
                </div>


                <div fxFlex="100" class="mt-2 w-100">
                    <button style="background-color: #1976d2; color: white;" mat-raised-button type="submit"
                        (click)="resetPassword()">
                        Reset
                    </button>

                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>