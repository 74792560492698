import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";
import { ChangePasswordComponent } from "../../user-management/change-password/change-password.component";
import { User } from "src/app/models/user";
import { EditProfileComponent } from "../../user-management/edit-profile/edit-profile.component";
import { userService } from "src/app/services/userManagement.service";

@Component({
  selector: "app-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  public userImage = "assets/images/others/user3.jpg";
  user: User | null = null;
  userId: string = "";
  constructor(
    private _authService: AuthService,
    private _router: Router,
    public dialog: MatDialog,
    private service: userService,
  ) {}

  ngOnInit(): void {
    this.loadUserProfile();
  }
  logout() {
    this._authService.logout();
    this._router.navigateByUrl("/auth/login");
  }
  loadUserProfile() {
    const profileusername = localStorage.getItem("_id");
    const id = profileusername.replace(/['"]+/g, "");
    console.log(id);

    this.service.getprofileByID(id).subscribe((data) => {
      this.user = data;
      console.log(this.user);
    });
  }
  public openUserEditDialog(user) {
    let dialogRef = this.dialog
      .open(EditProfileComponent, {
        data: {
          user: user,
        },
        width: "30%",
      })
      .afterClosed()
      .subscribe((result) => {
        this.ngOnInit();
      });
  }
  // public openUserDialog(user) {
  //   let dialogRef = this.dialog
  //     .open(ChangePasswordComponent, {
  //       data: {
  //         user: user,
  //       },
  //       width: "20%",
  //     })
  //     .afterClosed()
  //     .subscribe((result) => {
  //       this.ngOnInit();
  //     });
  // }
}
